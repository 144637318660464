import { useEffect, useContext } from "react";
import { navigate } from "gatsby";

import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { LuxeContext } from "../context/LuxeProvider";
import { useAuth } from "../context/AuthProvider";
import useDeviceDetect from "../hooks/useDeviceDetect";
import { isBrowser } from "../context/helpers";

import ALRewardsHeader from "./ALRewardsHeader";
import ALRewardsNavigation from "./ALRewardsNavigation";
import MembershipCredit from "./luxe/MembershipCredit";
import BillingHistory from "./luxe/BillingHistory";
import MembershipDetails from "./luxe/MembershipDetails";
import FaqLuxe from "./FaqLuxe";
import RedeemPoints from "./RedeemPoints";

import * as Styles from "./luxe/styles.module.scss";

function Luxe() {
  const { luxe } = useContext(LuxeContext);
  const { isMobile } = useDeviceDetect();
  const { accountDetails } = useAuth();

  if (!accountDetails && isBrowser) {
    navigate("/?auth=login");
  }

  const subscription = luxe?.customerSubscription;

  useEffect(() => {
    if ((!luxe?.isLuxeEnabled || !luxe?.customerSubscription) && luxe.accountDetailsLoaded) {
      navigate("/account");
    }
  }, [luxe]);

  if (!luxe?.isLuxeEnabled || !subscription) {
    return null;
  }

  return (
    <>
      {isMobile && <ALRewardsNavigation />}
      <ALRewardsHeader type="luxe" />
      <div className={Styles.container}>
        {!isMobile && <ALRewardsNavigation />}
        <MembershipCredit />
        <BillingHistory />
        <MembershipDetails />
        <RedeemPoints type="luxe" />
        <FaqLuxe />
      </div>
    </>
  );
}

export default withALErrorBoundary({
  name: "Luxe",
  priority: "P2",
})(Luxe);
