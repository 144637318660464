import useDeviceDetect from "../hooks/useDeviceDetect";
import Accordion from "./al_components/ALAccordion";

import "./faqLuxe/styles.scss";

function FaqLuxe() {
  const { isMobile } = useDeviceDetect();
  return (
    <div>
      <h2 className="luxe_faq_title h2 h2--bold h2--uc">
        {isMobile ? "FAQ" : "Frequently Asked Questions"}
      </h2>
      <div>
        <Accordion title="What is AL Luxe?" isOpen>
          <div className="luxe_faq_answer subtext">
            <p>
              AL Luxe is Ana Luisa's exclusive membership program. This subscription-based
              membership offers total shopping freedom and a long list of perks – buildable store
              credit, additional discounts, and priority customer support – all for $39.99 a month.
            </p>
            <p>
              Becoming Luxe is simple! You'll see the option to sign up on our checkout page. Pause,
              skip, or cancel at any time (it really is that easy). At this time, AL Luxe is only
              available for customers based in the United States.
            </p>
          </div>
        </Accordion>
        <Accordion title="What is included in my AL Luxe membership?">
          <div className="luxe_faq_answer subtext">
            <p>Discount Perks</p>
            <p>
              - Buy More, Save More: Receive an additional 5% off during our sitewide promotions (up
              to 30% off)
            </p>
            <p>
              - Receive 5% Off Solid Gold Products: Exclusive to AL Luxe members (this the only way
              to save on our fine jewelry line!)
            </p>
            <p>- Free Shipping: All your orders ship free (yes, all of them)</p>
            <br />
            <p>Sale Perks</p>
            <p>
              - Early Access: Receive notifications about our sales, and get the chance to shop
              before anyone else
            </p>
            <p>
              - Members-Only Sales: More sales throughout the year, exclusively catered just for you
            </p>
            <p>- $$$ Off: Use your points to unlock money off future purchases</p>
            <p>
              - Priority Customer Support: Need help with your order? We’ll respond faster and offer
              a more personalized experience
            </p>
          </div>
        </Accordion>
        <Accordion title="Does AL Luxe have a monthly fee?">
          <div className="luxe_faq_answer subtext">
            <p>
              Yes, members of AL Luxe will be charged $39.99 per month. For your first month of
              membership, you will be billed 30 days after signing up. After that, you will be
              charged monthly. Your store credit will be updated automatically, and you can use your
              balance to make purchases right away.
            </p>
          </div>
        </Accordion>
        <Accordion title="Do I have to shop every month as a member of AL Luxe?">
          <div className="luxe_faq_answer subtext">
            <p>
              No, you do not. The AL Luxe membership is flexible, so you don't need to make a
              purchase every month to maintain your member status. Feel free to spend or save your
              store credit however you like.
            </p>
          </div>
        </Accordion>
        <Accordion title="I'd like to update the payment method for my subscription.">
          <div className="luxe_faq_answer subtext">
            <p>
              We are happy to help with any changes to your AL Luxe membership. To make changes to
              the payment method associated with your account, please email our team at
              love@analuisa.com. We will send over a secure link so you can update the payment
              method yourself. Please note our team does not offer phone support and cannot take any
              payment information over the phone.
            </p>
          </div>
        </Accordion>
        <Accordion title="I'd like to cancel my membership.">
          <div className="luxe_faq_answer subtext">
            <p>
              We’ll miss you, but we understand some things don’t last forever. To cancel your AL
              Luxe membership, follow these steps.
            </p>
            <p>1. Log into your account.</p>
            <p>2. Click the section of your account page labeled "Manage Membership".</p>
            <p>
              3. Click the button labeled "Cancel Membership" listed underneath your personal
              details.
            </p>
            <p>
              4. A pop-up will appear on the page. Follow the prompts on the screen to tell us why
              you are ending your membership and finalize the cancellation.
            </p>
            <p>
              In addition to canceling, you have the ability to pause your membership at any time
              for up to three months. The steps to pause your membership is the same as canceling
              (You will see the option for both in the pop-up). If you decide to pause your
              membership, your membership will automatically renew at the end of the pause period.
            </p>
          </div>
        </Accordion>
      </div>
    </div>
  );
}

export default FaqLuxe;
