import { useState, useCallback, useContext } from "react";
import axios from "axios";

import { useALError, withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { LuxeContext } from "../../context/LuxeProvider";
import { useTracking } from "../../context/Tracking";
import { useAuth } from "../../context/AuthProvider";

import SkipSubscriptionModal from "./membershipDetails/SkipSubscriptionModal";
import ReactivateSubscriptionModal from "./membershipDetails/ReactivateSubscriptionModal";

import { ALLink, ALButton } from "../ALComponents";

import * as Styles from "./membershipDetails/styles.module.scss";

function MembershipDetails() {
  const { accountDetails } = useAuth();
  const { luxe, updateSubscriptionStatus, updateSubscriptionNextBillingDate } =
    useContext(LuxeContext);
  const { trackSubscriptionSkip, trackSubscriptionReactivate } = useTracking();
  const { sendReport } = useALError();
  const subscription = luxe?.customerSubscription;

  const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
  const [isReactivateModalOpen, setIsReactivateModalOpen] = useState(false);

  const showSkipModal = () => {
    setIsSkipModalOpen(true);
  };

  const showReactivationModal = () => {
    setIsReactivateModalOpen(true);
  };

  const onSkipSubscription = useCallback(async () => {
    try {
      const res = await axios.post(
        `/.netlify/functions/subscription`,
        {},
        {
          params: {
            cid: accountDetails.id.replace("gid://shopify/Customer/", ""),
            email: accountDetails.email,
            action: "PAUSE",
            period: "1",
          },
        }
      );

      if (res.status === 200) {
        const skippedSubscription = await axios.get(`/.netlify/functions/subscription`, {
          params: {
            cid: accountDetails.id.replace("gid://shopify/Customer/", ""),
            email: accountDetails.email,
          },
        });
        if (skippedSubscription.status === 200) {
          updateSubscriptionNextBillingDate(skippedSubscription.data.membership.next_billing_date);
          updateSubscriptionStatus(skippedSubscription.data.membership.status);
          trackSubscriptionSkip();
        }
      }
    } catch (e) {
      sendReport(e, {
        name: "MembershipDetailsSkipSubscription",
        priority: "P3",
      });
    }
  }, [
    accountDetails,
    sendReport,
    trackSubscriptionSkip,
    updateSubscriptionStatus,
    updateSubscriptionNextBillingDate,
  ]);

  const onReactivateSubscription = useCallback(async () => {
    try {
      const res = await axios.post(
        `/.netlify/functions/subscription`,
        {},
        {
          params: {
            cid: accountDetails.id.replace("gid://shopify/Customer/", ""),
            email: accountDetails.email,
            action: "REACTIVATE",
          },
        }
      );
      if (res.status === 200) {
        const updatedSubscription = await axios.get(`/.netlify/functions/subscription`, {
          params: {
            cid: accountDetails.id.replace("gid://shopify/Customer/", ""),
            email: accountDetails.email,
          },
        });
        if (updatedSubscription.status === 200) {
          updateSubscriptionNextBillingDate(updatedSubscription.data.membership.next_billing_date);
          updateSubscriptionStatus(updatedSubscription.data.membership.status);
          trackSubscriptionReactivate();
        }
      }
    } catch (e) {
      sendReport(e, {
        name: "MembershipDetailsReactivateSubscription",
        priority: "P3",
      });
    }
  }, [
    accountDetails,
    sendReport,
    trackSubscriptionReactivate,
    updateSubscriptionStatus,
    updateSubscriptionNextBillingDate,
  ]);

  return (
    <>
      <h2 className="h2 h2--bold h2--uc m-0 mb-20">Membership Details</h2>
      <div className={Styles.container}>
        <div className={Styles.section}>
          <div className="mb-15">
            <span className="text text--bold">Membership Type</span>
            <span className={`${Styles.memberTag} microtext microtext--bold`}>AL Luxe Member</span>
          </div>
          <div className="mb-15">
            <span className="text text--bold">Membership Status</span>
            <span className={`${Styles.memberStatus} text`}>
              {subscription.status.toLowerCase()}
            </span>
          </div>
          <div>
            <span className="text text--bold">Member Since</span>
            <span className="text">{subscription.member_since}</span>
          </div>
        </div>

        <div className={Styles.section}>
          <div>
            <span className="text text--bold">Next payment</span>
            <span className="text" data-testid="subscription-next-payment">
              {subscription.next_billing ? subscription.next_billing : "-"}
            </span>
          </div>
        </div>

        <div className={Styles.actions}>
          {subscription.status === "ACTIVE" && (
            <ALButton size="medium" variant="secondary" onClick={showSkipModal}>
              Skip this month
            </ALButton>
          )}

          {(subscription.status === "PAUSED" || subscription.status === "CANCELLED") && (
            <ALButton size="medium" variant="secondary" onClick={showReactivationModal}>
              Reactivate membership
            </ALButton>
          )}

          {subscription.status === "ACTIVE" && (
            <ALLink to="/ana-luisa-luxe/cancel">
              <ALButton size="large" variant="secondary">
                Cancel membership
              </ALButton>
            </ALLink>
          )}
        </div>
      </div>

      <SkipSubscriptionModal
        isOpen={isSkipModalOpen}
        subscriptionStatus={subscription.status}
        onAction={onSkipSubscription}
        onClose={() => setIsSkipModalOpen(false)}
      />

      <ReactivateSubscriptionModal
        isOpen={isReactivateModalOpen}
        subscriptionStatus={subscription.status}
        onAction={onReactivateSubscription}
        onClose={() => setIsReactivateModalOpen(false)}
      />
    </>
  );
}

export default withALErrorBoundary({
  name: "MembershipDetails",
  priority: "P3",
})(MembershipDetails);
