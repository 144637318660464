import { useContext } from "react";
import { LuxeContext } from "../../context/LuxeProvider";

import * as Styles from "./styles.module.scss";

function MembershipCredit() {
  const { luxe } = useContext(LuxeContext);

  return (
    <div className={Styles.membership_credit}>
      <h2 className="h2 h2--bold h2--uc m-0 mb-20">Membership credit</h2>

      <div className={Styles.membership_credit_content}>
        <p className="h3 h3--bold mb-20">${luxe.subscriptionCredit} credit</p>
        <p className="subtext">Make sure you’re logged in and apply your credit at checkout!</p>
      </div>
    </div>
  );
}

export default MembershipCredit;
